/**
 * Written by Ayaan and Linh
 */

import { DndContext, useDraggable, useDroppable, pointerWithin } from "@dnd-kit/core";
import { useState, useEffect } from "react";
import { BACKEND_LINK } from "../../ultilities/global.links";
import "../../assets/styles/drag-in-styles.css";

function DraggableCloth({ id, children }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 1000,
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className="draggable-wrapper"
    >
      {children}
    </div>
  );
}

function DroppableRock({ id, children, rockItem }) {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <div 
      ref={setNodeRef} 
      className={`rock-dropzone ${isOver ? 'is-over' : ''}`}
      style={{
        position: 'absolute',
        top: `calc(var(--screen-height) * (${rockItem.top} / var(--base-height)))`,
        left: `calc(var(--screen-width) * (${rockItem.left} / var(--base-width)))`,
        width: `calc(var(--screen-width) * (${rockItem.width} / var(--base-width)))`,
        height: `calc(var(--screen-height) * (${rockItem.height} / var(--base-height)))`,
      }}
    >
      {children}
    </div>
  );
}

const DragIn = ({ options, setSelectorReturnValue }) => {
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [currentRock, setCurrentRock] = useState('rock1');
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const newItems = options.map(item => ({
      id: item.name,
      type: item.type,
      media: item.media,
      width: item.width,
      height: item.height,
      top: item.top,
      left: item.left,
      location: 'outside'
    }));
    setItems(newItems);
  }, [options]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    console.log('Drag ended:', {
      active: active,
      over: over,
      activeId: active?.id,
      overId: over?.id
    });
    
    if (over && active.id === 'cloth' && over.id === 'rock') {
      console.log('Drop condition met!');
      if (currentRock === 'rock1') {
        setCurrentRock('rock2');
        setSelectorReturnValue("changeFooterText");
      } else if (currentRock === 'rock2') {
        setCurrentRock('rock3');
        setSelectorReturnValue("changeFooterText");
        setIsComplete(true);
      } else {
        setSelectorReturnValue("changeFooterText");
      }
    }
    
    setActiveId(null);
  };

  const rockItem = items.find(item => item.id === currentRock);
  const clothItem = items.find(item => item.id === 'cloth');

  if (!items.length) return null;

  return (
    <div className="drag-in-container" style={{ position: 'relative' }}>
      <DndContext 
        onDragStart={handleDragStart} 
        onDragEnd={handleDragEnd}
        collisionDetection={pointerWithin}
      >
        <DroppableRock id="rock" rockItem={rockItem}>
          {rockItem && (
            <img
              src={BACKEND_LINK + rockItem.media}
              alt="Rock"
              style={{
                width: '100%',
                height: '100%',
                pointerEvents: 'none'
              }}
            />
          )}
        </DroppableRock>

        {!isComplete && clothItem && (
          <DraggableCloth id="cloth">
            <img
              src={BACKEND_LINK + clothItem.media}
              alt="Cloth"
              style={{
                position: 'absolute',
                top: `calc(var(--screen-height) * (${clothItem.top} / var(--base-height)))`,
                left: `calc(var(--screen-width) * (${clothItem.left} / var(--base-width)))`,
                width: `calc(var(--screen-width) * (${clothItem.width} / var(--base-width)))`,
                height: `calc(var(--screen-height) * (${clothItem.height} / var(--base-height)))`
              }}
            />
          </DraggableCloth>
        )}
      </DndContext>
    </div>
  );
};

export default DragIn;
