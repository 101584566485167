/**
 * Written by Ronghua and Linh
 */

import { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useDispatch, useSelector } from "react-redux";
import { globalContext } from "../../../Context";
import { playAudio, stopAudio } from "../../../ultilities/redux.audioSlice.js";
import { SOUND_LINK } from "../../../ultilities/global.links.js";

import highlightedImage from "../../backpacks/HighlightImage.js";

import muteIcon from "../../../assets/icons/UI_Mute.png";
import speakIcon from "../../../assets/icons/UI_Speak.png";
import "../../../assets/styles/buttons-styles.css";
import "../../../assets/styles/lesson-selection-styles.css";

export default function SpeakingButton({ highlighted }) {
  // VO handle
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const playing = useSelector((state) => state.audio.playing);

  const { state, isSpanish } =
    useContext(globalContext);

  const [num, setNum] = useState(isSpanish ? 1 : 0);

  const handlePlay = () => {
    if (playing) {
      dispatch(stopAudio());    
    } else {
      dispatch(playAudio({ id: "slideVO" }));;
    }
  };

  const controlPlayer = (action) => {
    const player = audioRef.current?.getInternalPlayer();
    if (player && typeof player[action] === "function") {
      player[action](); // Call play() or pause()
    }
  };

  useEffect(() => {
    if (playing === "slideVO") {
      controlPlayer("play");
    } else {
      controlPlayer("pause"); // Pause if not playing
    }
  }, [playing]);

   // for spanish vo
   useEffect(() => {
    if (isSpanish) {
      setNum(1);
    } else {
      setNum(0);
    }
  }, [isSpanish]);

  return (
    <div className="speaking-button-container">
      <ReactPlayer
        url={
          SOUND_LINK +
          (state.voiceovers !== undefined ? state.voiceovers[num] : "")
        }
        playing={playing}
        ref={audioRef}
      />
      <img
        src={playing ? speakIcon : muteIcon}
        style={{ height: "100%", ...(highlighted && highlightedImage) }}
        onClick={() => {
          handlePlay();
        }}
        alt="Speaking icon"
      ></img>
    </div>
  );
}
