/**
 * Written by Ayaan and Linh
 */

import {
  DndContext,
  DragOverlay,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import useSound from "use-sound";

import "../../assets/styles/drag-drop-styles.css";
import * as link from "../../ultilities/global.links.js";
import { playAudio, stopAudio } from "../../ultilities/redux.audioSlice";
import { IncorrectPopUp } from "../display/PopUp";

// DraggableStory component
function DraggableStory({ id, children }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 1000, // Add this line to ensure it's on top when dragging
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className="dd-draggable-wrapper"
    >
      {children}
    </div>
  );
}

// DroppableStory component
function DroppableStory({ id, children, isOccupied }) {
  const { setNodeRef } = useDroppable({
    id: id,
    disabled: isOccupied,
  });

  return (
    <div ref={setNodeRef} className="dd-droppable-container">
      <div className="dd-droppable-zone">{children}</div>
    </div>
  );
}

// Main DragAndDrop component
function DragAndDrop({
  isSpanish,
  options,
  audioSource,
  setSelectorReturnValue,
  gridCount = 4,
  behavior,
}) {
  // VO handle
  const dispatch = useDispatch();
  const incorrectVORef = useRef(null);
  const playing = useSelector((state) => state.audio.playing);

  const [dropAudio] = audioSource;
  const [items, setItems] = useState(
    options.map((item, index) => {
      return {
        id: `item-${index}`,
        location: "outside",
        media: link.BACKEND_LINK + item.media,
        name: item.name,
      };
    })
  );
  const [activeId, setActiveId] = useState(null);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);

  const [playIncorrectSFX] = useSound(link.INCORRECT_SOUND, {
    volume: 0.25,
    loop: false,
  });

  const [playDropSound] = useSound(
    dropAudio ? link.BACKEND_LINK + dropAudio : null,
    {
      volume: 0.25,
      loop: false,
    }
  );

  const gridIds = Array.from({ length: gridCount }, (_, i) =>
    String.fromCharCode(65 + i)
  ); // Generates ['A', 'B', 'C', 'D'] for 4 or ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'] for 8

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && gridIds.includes(over.id)) {
      if (dropAudio) {
        playDropSound();
      }

      setItems((prevItems) => {
        const newItems = prevItems.map((item) => {
          if (item.id === active.id) {
            return { ...item, location: over.id };
          }
          if (item.location === over.id) {
            return { ...item, location: "outside" };
          }
          return item;
        });

        const filledAreas = newItems.filter((item) =>
          gridIds.includes(item.location)
        );

        if (filledAreas.length === gridCount) {
          const isAllCorrect = filledAreas.every((item) => {
            const isCorrect = item.name === "correctMultipleAnswer";
            return isCorrect;
          });

          if (isAllCorrect) {
            if (behavior === "changeFooterText") {
              setSelectorReturnValue("changeFooterText");
            } else {
              setSelectorReturnValue("turnOnNextBtn");
            }
          } else {
            setShowIncorrectPopup(true);

            // audio
            playIncorrectSFX();
            if (playing === "slideVO") {
              dispatch(stopAudio());
              dispatch(playAudio({ id: "incorrectFeedbackVO" }));
            }

            return newItems.map((item) => {
              if (item.name !== "correctMultipleAnswer") {
                return { ...item, location: "outside" };
              }
              return item;
            });
          }
        }
        return newItems;
      });
    } else {
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === active.id ? { ...item, location: "outside" } : item
        )
      );
    }

    setActiveId(null);
  };

  const renderItem = (item) => (
    <DraggableStory key={item.id} id={item.id}>
      <div
        className={
          item.location !== "outside" ? "dd-dropped-item" : "dd-draggable-item"
        }
      >
        <img
          src={item.media}
          alt={item.name}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: (item.width * 4) / gridCount,
            height: (item.height * 4) / gridCount,
            objectFit: "contain",
          }}
        />
      </div>
    </DraggableStory>
  );

  const renderPlaceholder = (key) => (
    <div key={key} className="dd-placeholder-item dd-draggable-item">
      {/* Transparent placeholder */}
    </div>
  );

  const renderImages = (startIndex, endIndex) => {
    const sectionItems = items.slice(startIndex, endIndex);
    const itemsToRender = [];

    for (let i = 0; i < endIndex - startIndex; i++) {
      const item = sectionItems[i];
      if (item && item.location === "outside") {
        itemsToRender.push(renderItem(item));
      } else {
        itemsToRender.push(renderPlaceholder(`placeholder-${startIndex}-${i}`));
      }
    }

    return itemsToRender;
  };

  useEffect(() => {
    const newItems = options.map((item, index) => {
      return {
        id: `item-${index}`,
        location: "outside",
        media: process.env.REACT_APP_BACKEND_HOST + item.media,
        name: item.name,
      };
    });
    setItems(newItems);
    setShowIncorrectPopup(false);
  }, [options]);

  return (
    <>
      <div className="dd-component">
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div className="dd-container">
            <div className="dd-layout">
              <div className="dd-top-images">{renderImages(0, 2)}</div>
              <div className="dd-main-content">
                <div className="dd-left-images">{renderImages(2, 5)}</div>
                <div className={`dd-grid dd-grid-${gridCount}`}>
                  {gridIds.map((id) => {
                    const occupyingItem = items.find(
                      (item) => item.location === id
                    );
                    return (
                      <DroppableStory
                        key={id}
                        id={id}
                        isOccupied={!!occupyingItem}
                      >
                        {occupyingItem ? (
                          renderItem(occupyingItem)
                        ) : (
                          <div className="dd-drop-placeholder"></div>
                        )}
                      </DroppableStory>
                    );
                  })}
                </div>
                <div className="dd-right-images">{renderImages(5, 8)}</div>
              </div>
            </div>
          </div>
          <DragOverlay dropAnimation={null}>
            {activeId ? (
              <div className="dd-drag-overlay">
                {renderItem(items.find((item) => item.id === activeId))}
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
        {showIncorrectPopup && (
          <IncorrectPopUp
            isSpanish={isSpanish}
            setShowPopup={setShowIncorrectPopup}
          />
        )}
      </div>

      {/* default incorrect VO */}
      <ReactPlayer
        url={isSpanish ? link.INCORRECT_ES : link.INCORRECT_EN}
        playing={playing === "incorrectFeedbackVO"}
        ref={incorrectVORef}
      />
    </>
  );
}

export default DragAndDrop;
