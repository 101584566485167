export const BACKEND_LINK = process.env.REACT_APP_BACKEND_HOST;

export const SOUND_LINK = process.env.REACT_APP_AWS_SOUND;

export const INCORRECT_SOUND = SOUND_LINK + "audio/JINGLE_Incorrect-Answer.mp3";
export const CORRECT_SOUND = SOUND_LINK + "audio/JINGLE_Correct-Answer.mp3";

// voice overs
export const INCORRECT_EN = SOUND_LINK + "voiceOvers/Overall/Incorrect_Answer_English.wav";
export const INCORRECT_ES = SOUND_LINK + "voiceOvers/Overall/Incorrect_Answer_Spanish.wav";
