/**
 * Written by Alex, Ayaan, Jeanie, Linh, Ronghua and Pavani
 */

import { useContext, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { globalContext } from "../../Context";

import { BACKEND_LINK, SOUND_LINK } from "../../ultilities/global.links";

// assets
import "../../assets/styles/other-content-styles.css";
import "../../assets/styles/selection-styles.css";
import useScreenSize from "../../ultilities/useScreenSize";

// activities
import DragAndDrop from "../activities/DragDrop";
import ImageSlider from "../activities/ImageSlider";
import Matching from "../activities/Matching";
import MCLeftRight from "../activities/MCLeftRight";
import MCPicture from "../activities/MCPicture";
import MCPictureTextBottom from "../activities/MCPictureTextBottom";
import MCPictureTextTop from "../activities/MCPictureTextTop";
import MCButtons from "../activities/MCVerticalRight";
import Record from "../activities/Record";
import TimerClick from "../activities/TimerClick";
import PrefixMatching from "../activities/PrefixMatching";
import DragIn from "../activities/DragIn";
import TimerClickAndDrag from "../activities/TimerClickAndDrag";

import {
  LeftRightDisplay,
  LeftRightExample,
  PictureInBtnExample,
} from "../display/MCExample";
import RedTextBox from "../display/RedTextBox";
import VocabPostIt from "../display/VocabPostIt";
import Paragraph from "../display/Paragraph.js";
import RecordWithWord from "../activities/RecordWithWord";

const defaultStyleOutput = {
  position: "absolute",
  objectFit: "contain",
  top: "0vh",
  left: "0vw",
  width: "60vw",
  height: "60vh",
};

const Content = ({ content, setSelectorReturnValue }) => {
  const {
    slideId,
    setSlideId,
    state,
    setState,
    lessonId,
    setLessonId,
    isSpanish,
    setIsSpanish,
    audioSource,
    setAudioSource,
  } = useContext(globalContext);

  const screenSize = useScreenSize();

  // common to all content types
  let style = {
    ...defaultStyleOutput,
    top: (content.top / 832) * screenSize.height + "px",
    left: (content.left / 1280) * screenSize.width + "px",
    width: (content.width / 1280) * screenSize.width + "px",
    height: (content.height / 832) * screenSize.height + "px",
  };

  // handle legacy schema
  // remove this if we ever need to use array media
  if (Array.isArray(content.media)) {
    content.media = content.media[0];
  }

  const [multipleClickedObjects, setMultipleClickedObjects] = useState([]);

  // handle the different items that are displayed on the content page
  // we have: sprite, selector (verticalRight, horizontal, leftRight), click-on, record, selection
  switch (content.type) {
    // ========== SPRITE ==========

    case "sprite": // Alex
      /**
       * sprite content type: use for static images, gifs, mp4s, etc
       * expected fields:
       * type: sprite
       * media is an array of strings - should really be one string but it's an array for legacy reasons
       * top, left, width, and height correspond to positioning of the media
       */

      // TODO: look into doing this character selection with a template
      // character selection
      if (
        localStorage.getItem("volcanologistColor") !== null &&
        content.media.includes("{{volcanologistColor}}")
      ) {
        content.media = content.media.replace(
          "{{volcanologistColor}}",
          localStorage.getItem("volcanologistColor")
        );
      }

      return (
        <img
          src={BACKEND_LINK + content.media}
          style={style}
          alt={`${content.media}`}
        />
      );

    // ========== SELECTOR ==========

    case "selector":
      /**
       * selector content type: multiple clickables
       * each clickable will set some kind of state - handle it as such
       * expected fields:
       * type: selector
       * clickables: array of objs (either sprite or option)
       * format (optional): choose from predefined format for mcq
       * each sprite has top, left, width, and height, and media like sprite above
       * each option has text
       * both also has a field "name", which is what is passed to the state
       */
      if (content.format !== undefined) {
        switch (content.format) {
          // the multiple choice where we have buttons as options on the right
          // hand side of sprites
          case "verticalRight": // Linh
            return (
              <MCButtons
                isSpanish={isSpanish}
                options={content.clickables}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
                audioSource={content.audioSource}
              />
            );

          // the multiple choice where we have picture inside each button, the
          // picture is on top and the text is at the bottom inside the button
          case "pictureTextBottom": // Linh
            return (
              <MCPictureTextBottom
                isSpanish={isSpanish}
                question={isSpanish ? content.questionEs : content.question}
                options={content.clickables}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
                audioSource={content.audioSource}
              />
            );

          // the multiiple choice where the options are ontop of the pictures
          // options and pictures are clickable
          case "multiPictures": // Jeanie
            return (
              <MCPicture
                isSpanish={isSpanish}
                options={content.options}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
                audioSource={content.audioSource}
              />
            );

          // the multiple choice where the options are on the left and right of
          // the sprites
          case "leftRight": // Linh
            return (
              <MCLeftRight
                isSpanish={isSpanish}
                question={isSpanish ? content.questionEs : content.question}
                options={content.clickables}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
                audioSource={content.audioSource}
              />
            );

          case "pictureTextTop": // Pavanie
            return (
              <MCPictureTextTop
                isSpanish={isSpanish}
                question={isSpanish ? content.questionEs : content.question}
                options={content.clickables}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
                audioSource={content.audioSource}
              />
            );

          case "timerClick": // Ayaan
            return (
              <TimerClick
                options={content.clickables}
                setSelectorReturnValue={setSelectorReturnValue}
              />
            );

          default:
            <></>;
        }
      } else {
        // Alex
        content.clickables.forEach(function (element) {
          element.style = {
            ...defaultStyleOutput,
            top: (element.top / 832) * screenSize.height + "px",
            left: (element.left / 1280) * screenSize.width + "px",
            width: (element.width / 1280) * screenSize.width + "px",
            height: (element.height / 832) * screenSize.height + "px",
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          };

          // audio handle
          element.onClick = () => {
            // if (content.audioSource) {
            //   document.getElementById("one_shot_audio").play();
            // }
            // special case for drag-drop
            if (element.name === "correctMultipleAnswer") {
              setMultipleClickedObjects([
                ...multipleClickedObjects,
                element.media,
              ]);
            }
            setSelectorReturnValue(element.name);
          };
        });

        return (
          <>
            {content.clickables.map((element) => (
              <>
                {element.type === "sprite" ? (
                  <img
                    style={
                      element.name !== "correctMultipleAnswer" ||
                      !multipleClickedObjects.includes(element.media)
                        ? element.style
                        : element.endStyle
                    }
                    onClick={element.onClick}
                    src={BACKEND_LINK + element.media}
                    alt={`${element.media}`}
                  />
                ) : element.type === "option" ? (
                  "unimplemented"
                ) : (
                  <></>
                )}
              </>
            ))}
          </>
        );
      }
      break;

    case "drag":
      if (content.format !== undefined) {
        switch (content.format) {

          // drag items into desinated grid, after being dragged, items display at a new location
          case "dragAndDrop": // Ayaan
            const correctAnswersCount = content.clickables.filter(
              (item) => item.name === "correctMultipleAnswer"
            ).length;

            return (
              <DragAndDrop
                isSpanish={isSpanish}
                options={content.clickables}
                setSelectorReturnValue={setSelectorReturnValue}
                gridCount={correctAnswersCount}
                audioSource={content.audioSource}
                behavior={content.behavior}
              />
            );
          
          // match items by dragging them into the correct spot
          case "matching": // Ayaan
            return (
              <Matching
                isSpanish={isSpanish}
                message={isSpanish ? content.messageEs : content.message}
                options={content.clickables}
                setSelectorReturnValue={setSelectorReturnValue}
                audioSource={content.audioSource}
                behavior={content.behavior}
              />
            );

          // for connecting words with its prefix
          case "prefixMatching": // Ayaan
            return (
              <PrefixMatching
                clickables={content.clickables}
                setSelectorReturnValue={setSelectorReturnValue}
                isSpanish={isSpanish}
                behavior={content.behavior}
              />
            );

          case "dragInto": // Ayaan
            return (
              <DragIn
                options={content.clickables}
                setSelectorReturnValue={setSelectorReturnValue}
              />
            );

          // drag item into a sprite, after that, items will disappear
          case "timerDrag": // Jeanie
            return (
              <TimerClickAndDrag
                options={content.options}
                sources={content.sources}
                setSelectorReturnValue={setSelectorReturnValue}
              />
            );
          default:
            <></>;
        }
        break;
      }

    // use when we need to click on an item and it moves on the next slide
    case "click-on": // Linh
      const handleClick = () => {
        setSelectorReturnValue("clickOn");
      };
      return (
        <img
          src={BACKEND_LINK + content.media}
          style={style}
          alt={`${content.media}`}
          onClick={handleClick}
        />
      );

    // when introduce the new vocab in the post-it
    case "vocab": // Linh
      return <VocabPostIt text={isSpanish ? content.textEs : content.text} />;

    // mainly for lesson 1 so far
    case "slider": // Ronghua
      if (content.changingImages.media !== null) {
        content.changingImages[0].media.map((item) => {
          if (typeof item[0] === "string") {
            item[0] = item[0].replace(
              "{{volcanologistColor}}",
              localStorage.getItem("volcanologistColor")
            );
          }
        });
      }
      return (
        <ImageSlider
          changingImages={content.changingImages}
          sliderStyle={content.sliderStyle}
          audioSources={content.audioSources}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      );

    // use when we need to record audio
    case "record": // Linh
      return (
        <Record
          isSpanish={isSpanish}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      );

    case "recordWithWord": // Linh
      return (
        <RecordWithWord
          isSpanish={isSpanish}
          text={isSpanish ? content.textEs : content.text}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      );

    // some questions have example displays
    case "example": // Linh
      if (content.format !== undefined) {
        switch (content.format) {
          case "pictureInBtn":
            return (
              <PictureInBtnExample
                isSpanish={isSpanish}
                question={isSpanish ? content.questionEs : content.question}
                options={content.options}
              />
            );
          case "leftRight":
            return (
              <LeftRightExample
                isSpanish={isSpanish}
                question={isSpanish ? content.questionEs : content.question}
                options={content.options}
              />
            );
          // display of the vocab
          case "leftRightDisplay":
            return (
              <LeftRightDisplay
                isSpanish={isSpanish}
                options={content.options}
              />
            );
          default:
            // unknown - error reporting TODO
            return <></>;
        }
      }
      break;

    // the blue rectangle with red text only to display the word
    case "redTextBox": // Ronghua
      return (
        <RedTextBox
          width={content.width}
          height={content.height}
          top={content.top}
          left={content.left}
          text={isSpanish ? content.textEs : content.text}
          fontSize={content.fontSize}
        />
      );

    case "paragraph": // Ronghua
      return (
        <Paragraph
          isSpanish={isSpanish}
          markedText={content.markedText}
          text={isSpanish ? content.textEs : content.text}
        />
      );

    // ========== AUDIO ==========
    case "autoplayAudio":
      return (
        <ReactPlayer
          url={SOUND_LINK + content.audioSource}
          loop={content.loop}
          playing={true}
          onReady={() => null}
          volume={0.4}
        />
      );

    default:
      // unknown - error reporting TODO
      return <></>;
  }
};

export default Content;
